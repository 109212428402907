import { configureStore, createSlice, PayloadAction, UnknownAction } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query'

import { mainApi } from './api/main';
import counterReducer from './counterSlice';

interface CounterState {
  value: number
}

// Define the initial state using that type
const initialState: CounterState = {
  value: 0,
}

const myObjectSlice = createSlice({
  name: 'myObject',
  initialState,
  reducers: {
    // Экшен для обновления всего объекта
    setMyObject: (state, action: PayloadAction<MyObject>) => {
      return { ...state, ...action.payload };
    },
    // Экшен для обновления только данных соединения
   /*  updateConnectionData: (state, action: PayloadAction<ConnectionData>) => {
      state.connection_data = { ...state.connection_data, ...action.payload };
    } */
  }
});


export const { setMyObject } = myObjectSlice.actions;
export const myObjectReducer = myObjectSlice.reducer;

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [mainApi.reducerPath]: mainApi.reducer,
    counter: counterReducer, // Подключаем reducer


   // counter: counterReducer,
   /*  setMyObject: (state, action: PayloadAction<MyObject>) => {
      return { ...state, ...action.payload };
    }, */
    // Экшен для обновления только данных соединения
  /*   updateConnectionData: (state, action: PayloadAction<ConnectionData>) => {
      state.connection_data = { ...state.connection_data, ...action.payload };
    } */
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(mainApi.middleware),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)



interface ConnectionData {
  owner: string;
  name: string;
  IP: string;
}

// Основной интерфейс для объекта
interface MyObject {
  uuid: string;
  status: string;
  connection_data: ConnectionData;
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
