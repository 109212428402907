import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    name: "",
    date: "",
    uuid: ""
  },
  reducers: {
    setUser: (state,action) => {
      state.name = action.payload.name 
      state.date = action.payload.date
    },

    setUuid: (state,action) => {
      state.uuid = action.payload.uuid
    },
    
  },
})

export const { setUser, setUuid } = counterSlice.actions


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCount = (state:any) => state.counter.value
export const selectUser = (state:any) => state.counter.user

export default counterSlice.reducer
