import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'

interface ConnectionData {
  owner: string;
  connection_id: string;
  connection_name: string;
  connection_country_en: string;
  connection_country_ru: string;
  connection_country_flag: string;
  connection_country_domain: string;
  ip: string;
}

interface ApiResponse {
  location: string;
  is_whypn_client: boolean;
  ip: string;
}
export const mainApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: 'https://test.whyrl.me' }),
  tagTypes: ["Post"],
  endpoints: (builder) => ({

    getConn: builder.query<ApiResponse, any>({
      query: (info:any) => `/whypn/proxy/geo_ip`,
    }),
  }),


})


export interface Post {
  code: string
  uuid: string
}



// Export hooks for usage in functional components
export const { 
  useGetConnQuery,
} = mainApi
