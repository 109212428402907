import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Box, Button, Container } from '@mui/material';
import { useGetConnQuery } from '../redux/api/main';


export default function Extensions(props: any) {

    const { data: data1, error: error1, isLoading: isLoading1 } = useGetConnQuery("")

    var onHelpClick = () => {
        window.open("https://t.me/whypnbot", "_blank")
    }

    return (
        <Container maxWidth="lg" sx={{ textAlign: 'left' }} style={{
            display:'flex',
            alignItems:'center',
            height:'80VH'
          }}>
            {
                isLoading1
                    ? <></>
                    :
                    <Box>
                        <Box>
                            <Box>
                                <Typography sx={{ mt: 1 }} variant="h6" fontWeight={800} color={"white"} gutterBottom>Ваша локация по IP-адресу</Typography>
                                <Typography sx={{ mt: 1 }} variant="h4" fontWeight={800} color={"white"} gutterBottom>{data1?.location}</Typography>
                            </Box>
                        </Box>

                        {
                            data1?.is_whypn_client ?
                                <Box>
                                    <Box sx={{ mt: 5 }}>
                                        <Typography sx={{ mt: 1 }} variant="h4" fontWeight={800} color={"white"} gutterBottom>Поздравляем! 🎉</Typography>
                                        <Typography sx={{ mt: 1 }} variant="h4" fontWeight={800} color={"white"} gutterBottom>Вы под защитой WhyPN</Typography>
                                    </Box>
                                </Box>
                                : <Box>
                                    <Box sx={{ mt: 5}}>
                                        <Typography sx={{ mt: 1 }} variant="h4" fontWeight={800} color={"white"} gutterBottom>Ваше Интернет-подключение не защищено WhyPN</Typography>
                                        <Button onClick={onHelpClick} sx={{ mt: 4 }} className={"main__button"}>Защитите меня 🙏</Button>
                                    </Box>
                                </Box>
                        }
                    </Box>
            }

        </Container>
    );
}
